/* eslint react/destructuring-assignment: 0 */
/**
 * @todo refactoring
 */
import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { 
  Listing, 
  TimelineListing, 
  WorkshopListing, 
  Wrapper, 
  SEO, 
  Header 
} from '../components'
import website from '../../config/website'
import { LocaleContext } from '../components/Layout'
import theme from '../styles/theme'
// import MailchimpSubscribe from "react-mailchimp-subscribe"
import NewsletterForm from '../components/Newsletter';


const PageWrapper = styled.div`
  display:flex;
  p {
    margin-bottom:.5rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    > div {
      width: 30%!important;
      &:last-of-type {
        width: 70%!important;
        p {
          font-size: 14px
        }
      }
      &:first-of-type {
        padding-right: 1.5rem
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    display: block;
    > div {
      width: 100%!important;
      &:last-of-type {
        width: 100%!important;
        padding-top: 1.5rem;
        p {
          font-size: 18px
        }
      }
      &:first-of-type {
        padding-right: 0
      }
    }
  }
`
const ContactWrapper = styled.div`
  p {
    font-family: ${props => props.theme.fontFamily};
    text-align: center;
    font-size: 16px;
    a {
      color: ${props => props.theme.colors.black};
      font-style: normal;
      font-size: 16px;
    }
  } 
`
const Summary = styled.div`
  padding-top: 2rem;
  p, em {
    font-family: ${props => props.theme.fontFamily};
    font-size: 16px!important;
    padding: 0;
    margin: 0 0 10px 0;
  }
`
const SummaryCol = styled.div`
  padding-top: 1rem;
  p {
    font-size: 16px;
    padding: 0 2rem 0 0;
    margin: 0 0 10px 0;
    text-align: right;
  }
`
const SummaryColContact = styled.div`
  padding-top: 1rem;
  h6 {
    text-align: center;
    font-family: ${props => props.theme.fontFamily};
    margin: 80px 0 10px 0;
  }
  p {
    font-family: ${props => props.theme.fontFamily};
    font-size: 16px;
    padding: 0 0 0 0;
    margin: 0 0 10px 0;
    text-align: center;
    em {
      font-style: normal;
      margin: 40px 0 80px 0;
      font-size: 16px;
      display: block;
    }
    a {
      color: ${props => props.theme.colors.black};
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
  }
`
const WorksCategoriesMobileWrapper = styled.ul`
  display: flex;
  visibility: hidden;
  margin: 0;
  padding: 0;
  justify-content: center;
  li {
    list-style-type: none;
    margin: 0 .3rem;
    a {
      font-size: 13px;
      @media (max-width: ${props => props.theme.breakpoints.xxs}) {
        font-size: 10px;
      }
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    visibility: visible;
  }
`
const WorksMobileLink = styled(Link)`
  font-family: ${props => props.theme.fontFamily};
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.color};
  text-decoration: none!important;
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 12px;
  }
  @media (max-width: ${props => props.theme.breakpoints.xxs}) {
    font-size: 10px;
  }
`


const CategoryWrapper = Wrapper.withComponent('main')
const WorksCategoriesMobile = (props) => (
  <WorksCategoriesMobileWrapper style={props.style}>
    {props.items.map((category, index) => (
      <li key={`works-categories-item-${index}`}>
        <WorksMobileLink
          to={`/${category.item.uid}/`}
          color={props.slug === category.item.uid ? theme.colors.black : theme.colors.light}>
          {category.item.document[0].data.name}
        </WorksMobileLink>
      </li>
    ))}
  </WorksCategoriesMobileWrapper>
)
const CategorySummary = ({
  pageContext: { summary: { html : pageSummary } }, 
  slug
}) => (
  <React.Fragment>
    {(pageSummary && slug !== 'contact' && slug !== 'exhibition') && (
      <SummaryCol style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: pageSummary }} />
    )}
  </React.Fragment>
)
const PageContentWrapper = ({ 
  pageContext: { 
    summary: { html: pageSummary }, 
    page_content: { html: pageContent },
    image: { localFile } 
  }, 
  slug 
}) => (
  <React.Fragment>
    {(slug === 'contact' || slug === 'exhibition') ? (
      <React.Fragment>
        <div style={{ margin: "0 auto", width: "100%" }}>
          <ContactWrapper 
            style={{ width: "100%" }} 
            dangerouslySetInnerHTML={{ __html: pageContent }} 
          />
          { pageSummary &&
            <SummaryColContact 
              style={{ width: "100%" }} 
              dangerouslySetInnerHTML={{ __html: pageSummary }} 
            />
          }
        </div>
        {
          slug === 'contact' && (
            <React.Fragment>
              <div style={{
                marginTop: '4rem'
              }}>
                <NewsletterForm
                  uid="eb648200f481a06084c140afe"
                  aid="f3990af5e5"
                  fid="007ab1e0f0"
                  endpoint="dischr.us14.list-manage.com"
                  title="Newsletter"
                  style={{
                    titleBackground: '#f2f2f2',
                    layoutColor: '#494949'
                  }}
                  successMessage="Almost finished... We need to confirm your email address. To complete the subscription process, please click the link in the email we just sent you."
                />
              </div>
            </React.Fragment>
          )
        }
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div dangerouslySetInnerHTML={{ __html: pageContent }} style={{ width: localFile ? "50%" : "100%" }}></div>
      </React.Fragment>
    )}
  </React.Fragment>
);

const Listings = ({
  pageContext: { summary: { html: pageSummary } },
  orderedArtworksNodes,
  filters,
  slug
}) => (
  <React.Fragment>
    { (pageSummary && pageSummary !== '<p></p>')  && (
      <React.Fragment>
        <Summary dangerouslySetInnerHTML={{ __html: pageSummary }} />
        {/* {(slug === 'fonds-datelier' || slug === 'sometimes') && (
          <CtaDefault>Go to eShop</CtaDefault>
        )} */}
      </React.Fragment>
    ) }
    { slug === 'works' ? <TimelineListing {...filters} /> : <Listing posts={orderedArtworksNodes} /> }
  </React.Fragment>
);



export default ({ 
  location,
  pageContext,
  pageContext: { 
    category, 
    slug, 
    parent, 
    locale,
    page_content: { html: pageContent },
    image: { localFile } 
  },
  data,
  data: { 
    artworks: { edges: artworksNodes }, 
    filters,
    categoryOrder: { edges: [
      { node: { data: { items: categoryOrderedItems } } }
    ] },
    worksCategories: { edges: [
      { node: { data: { children: worksItems } } }
    ] },
    // instagram: { edges: instagramPosts }
    workshop: { edges: [
      { node: { data: { photos: workshopPosts } } }
    ] },
  }
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const context = { slug: slug, parent: parent };
  
  const orderedArtworksNodes = categoryOrderedItems.map((i) => {
    let nodeIndex = artworksNodes.findIndex((n) => n.node.uid === i.item.uid)
    return {
      node: {
        data: artworksNodes[nodeIndex].node.data,
        uid: i.item.uid
      }
    }
  });
  return (
    <React.Fragment>
      <SEO title={`${category}, ${i18n.defaultTitleAlt}`} pathname={location.pathname} locale={locale} />
      <Header {...context} />
      <CategoryWrapper id={website.skipNavId}>
        <WorksCategoriesMobile 
          items={worksItems} 
          style={{display: (slug === 'works' || parent === 'works') ? 'flex' : 'none'}} 
          slug={slug}
        />
        { pageContent ? (
          <PageWrapper style={{
            display: `${slug === 'contact' ? 'block' : 'flex'}`
          }}>
            <CategorySummary {...{ pageContext, slug }} />
            { pageContext.image.localFile && (
                <React.Fragment>
                  {
                    slug === 'contact' ? (
                      <div style={{ 
                        width: "100%"
                      }}>
                        <Img 
                          fluid={localFile.childImageSharp.fluid} 
                          style={{ 
                            maxWidth: 250, 
                            margin: '0 auto', 
                            marginBottom: '1rem' 
                          }}
                          placeholderStyle={{
                            filter: "grayscale(100%) contrast(100%)",
                            imageRendering: "pixelated",
                            opacity: 0,
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%", paddingTop: '.3rem'}}>
                        <Img 
                          fluid={localFile.childImageSharp.fluid} style={{ maxWidth: 450 }}
                          placeholderStyle={{
                            filter: "grayscale(100%) contrast(100%)",
                            imageRendering: "pixelated",
                            opacity: 0
                          }}
                        />
                      </div>
                    )
                  }
                </React.Fragment>
              )
            }
            <PageContentWrapper {...{ pageContext, slug }} />
          </PageWrapper>
        ) : (
          <>
            {
              slug === 'workshop' ? (
                <React.Fragment>
                  {/* <InstagramListing posts={instagramPosts} /> */}
                  <WorkshopListing posts={workshopPosts} />
                </React.Fragment>
              ) : (
                <Listings {...{pageContext, orderedArtworksNodes, filters, slug }} />
              )
            }
          </>
        )}
      </CategoryWrapper>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query CategoryPage($category: String!, $locale: String!) {
    artworks: allPrismicArtwork(
      filter: {
        lang: { eq: $locale }
        data: {
          categories: { elemMatch: { category: { document: { elemMatch: { data: { name: { eq: $category } } } } } } }
        }}
      ) {
      edges {
        node {
          uid
          data {
            meta_description
            meta_title
            title {
              html
              text
            }
            type
            available
            item {
              alt
              localFile {
                childImageSharp {
                  fixed(height: 800, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categories {
              ... on PrismicArtworkDataCategories {
                category {
                  uid
                }
              }
            }
            year {
              ... on PrismicArtworkDataYear {
                uid
              }
            }
          }
        }
      }
    }
    categoryOrder: allPrismicCategory(
      filter: {
        lang: { eq: $locale }
        data: { name: { eq: $category } } 
      }
    ) {
      edges {
        node {
          uid
          data {
            items {
              ... on PrismicCategoryDataItems {
                item {
                  uid
                }
              }
            }
          }
        }
      }
    }
    filters: allPrismicFilters(
      filter: { 
        lang: { eq: "fr-fr" },
        data: {
          availability: { eq: "true" }
        }
      }
      sort: { fields: [uid], order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            items {
              ... on PrismicFiltersDataItems {
                item {
                  uid
                  ... on PrismicFiltersDataItemsItem {
                    document {
                      data {
                        item {
                          localFile {
                            childImageSharp {
                              fixed(height: 800, quality: 100) {
                                ...GatsbyImageSharpFixed_withWebp
                              }
                            }
                            childImageSharp {
                              fluid(maxWidth: 800, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    social: allPrismicHeroLinks(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            body {
              primary {
                label {
                  text
                }
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
    worksCategories: allPrismicCategory(filter: {uid: {eq: "works"}, lang: {eq: $locale}}) {
      edges {
        node {
          uid
          data {
            children {
              ... on PrismicCategoryDataChildren {
                item {
                  ... on PrismicCategoryDataChildrenItem {
                    uid
                    document {
                      data {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    #instagram: allInstaNode(filter: {caption: {regex: "/(atelier|workshop)/"}}) {
    #  edges {
    #    node {
    #      id
    #      caption
    #      localFile {
    #        childImageSharp {
    #          fixed(width: 600, quality: 100) {
    #            ...GatsbyImageSharpFixed_withWebp
    #          }
    #        }
    #      }
    #    }
    #  }
    #}
    workshop: allPrismicWorkshop {
      edges {
        node {
          data {           
            photos {
              legend {
                text
              }
              photo {
                localFile {
                  childImageSharp {
                    fixed(height: 800, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
